import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import {AvailablePersistentAddresses} from './models/persistent-address.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {

  constructor(private apiService: ApiService) {}

  getOrganizations(): Observable<any> {
    return this.apiService.get(`/organizations/all`);
  }

  getCurrentOrganization(): Observable<any> {
    return this.apiService.get(`/organizations/current`);
  }

  getOrganization(organizationId): Observable<any> {
    return this.apiService.get(`/organizations/${organizationId}`);
  }

  createOrganization(data): Observable<any> {
    return this.apiService.post(`/organizations`, data);
  }

  updateOrganization(data): Observable<any> {
    return this.apiService.put(`/organizations/${data.id}`, data);
  }

  changeOrganization(data): Observable<any> {
    return this.apiService.put(`/auth/organizations/change`, data);
  }

  deleteOrganization(organizationId): Observable<any> {
    return this.apiService.delete(`/organizations/${organizationId}`);
  }

  getOrganizationLicenses(): Observable<any> {
    return this.apiService.get(`/licences`);
  }

  validateDns(dns: string) {
    return this.apiService.get(`/organizations/dns/${dns}`);
  }

  public getPersistentIps(region: string): Observable<AvailablePersistentAddresses> {
    return this.apiService.get(`/organizations/persistent-addresses/${region}`);
  }
}
