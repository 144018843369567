import {EventEmitter, Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { WebStorageService } from '../web-storage.service';

@Injectable({providedIn: 'root'})
export class AuthService {
  public onLogout: EventEmitter<void> = new EventEmitter<void>();

  constructor(private apiService: ApiService,
              private router: Router,
              private storageService: WebStorageService) {}

  signup(data: any): Observable<any> {
    return this.apiService.post(`/auth/signup`, data);
  }

  login(data: any): Observable<any> {
    return this.apiService.post(`/auth/login`, data);
  }

  resetPassword(data: {email: string}): Observable<any> {
    return this.apiService.post(`/auth/reset-password`, data);
  }

  setNewPassword(data: any): Observable<any> {
    return this.apiService.post(`/auth/new-password`, data);
  }

  logout(): void {
    this.storageService.deleteItem('token');
    this.storageService.deleteItem('organizationId');
    this.onLogout.emit();
    this.router.navigate(['/login']);
  }

  activateInvitation(data: any): Observable<any> {
    return this.apiService.post(`/new-password`, data);
  }
}
