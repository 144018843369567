import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-copy-link',
    templateUrl: './copy-link.component.html',
    styleUrls: ['./copy-link.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyLinkComponent {
    @Input() value: string;
    @Input() copyValue = '';
    @Input() iconPosition = 'none';
    @Input() showOpenIcon = false;
    @Input() openUrl = '';
    @Input() disabled = false;
    @Input() itemId: string;
    @Output() copiedItemId: EventEmitter<string> = new EventEmitter<string>();
    @Output() openTabItemId: EventEmitter<string> = new EventEmitter<string>();

    constructor(private snackBar: MatSnackBar) {
    }

    public onOpenNewTab(event: MouseEvent): void {
        event.stopPropagation();

        if (this.itemId) {
            this.openTabItemId.emit(this.itemId);
            return;
        }

        window.open(this.openUrl, '_blank');
    }

    public onCopy(event: MouseEvent): void {
        event.stopPropagation();

        if (this.itemId) {
            this.copiedItemId.emit(this.itemId);
            return;
        }

        this.snackBar.open('Copied to clipboard', 'Copy', {duration: 2000, panelClass: 'copy-link'});
    }
}
